@charset "utf-8";

// Import a Google Font
//@import url('https://fonts.googleapis.com/css2?family=MuseoModerno&family=Source+Sans+Pro:wght@300;400&display=swap');

// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
$family-secondary: "Oxygen", sans-serif;
$family-primary: "Open Sans", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: #802368;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

@import "node_modules/bulma/bulma.sass";

.navbar-item img {
    width: auto;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.family-header {
    font-family: $family-secondary;
}

.pgbcontent {
    flex: 1;
}
